import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import PeopleSectionHeader from '../../components/people/PeopleSectionHeader';
import { PeopleSectionStyles, PersonGrid, PersonContainer, PlacardStyle } from '../../styles/components/People';
import { GatsbyImage } from 'gatsby-plugin-image';

const PersonImageContainer = styled.div`
  width: 220px;
`;

/**
 * List of Spring 2022 PhD Candidates and Graduate Students
 * * Leo Liu
 * * Aniket Parlikar
 * * Ethan Remsberg
 * * Jason Tan
 * @returns
 */
const GraduateStudents = () => {
  const graduateStudentData = useStaticQuery(graphql`
    query GraduateStudentImages {
      noImage: file(relativePath: { eq: "no-profile-photo.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      leoImage: file(relativePath: { eq: "geng-liu.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      ethanImage: file(relativePath: { eq: "ethan-remsberg.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      jasonImage: file(relativePath: { eq: "jason-tan.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <PeopleSectionStyles className="people-section">
      <PeopleSectionHeader title="Graduate Students" />

      <PersonGrid>

        <PersonContainer className="person">
          <PersonImageContainer className="person-image-container">
            <GatsbyImage
              image={graduateStudentData.leoImage.childImageSharp.gatsbyImageData}
              alt="Leo Liu"
            />
          </PersonImageContainer>

          <PlacardStyle className="placard">
            <p>Leo Liu</p>
          </PlacardStyle>
        </PersonContainer>

        <PersonContainer className="person">
          <PersonImageContainer className="person-image-container">
            <GatsbyImage
              image={graduateStudentData.noImage.childImageSharp.gatsbyImageData}
              alt="Aniket Parlikar"
            />
          </PersonImageContainer>

          <PlacardStyle className="placard">
            <p>Aniket Parlikar</p>
          </PlacardStyle>
        </PersonContainer>

        <PersonContainer className="person">
          <PersonImageContainer className="person-image-container">
            <GatsbyImage
              image={graduateStudentData.ethanImage.childImageSharp.gatsbyImageData}
              alt="Ethan Remsberg"
            />
          </PersonImageContainer>

          <PlacardStyle className="placard">
            <p>Ethan Remsberg</p>
          </PlacardStyle>
        </PersonContainer>

        <PersonContainer className="person">
          <PersonImageContainer className="person-image-container">
            <GatsbyImage
              image={graduateStudentData.jasonImage.childImageSharp.gatsbyImageData}
              alt="Jason Tan"
            />
          </PersonImageContainer>

          <PlacardStyle className="placard">
            <p>Jason Tan</p>
          </PlacardStyle>
        </PersonContainer>

      </PersonGrid>
    </PeopleSectionStyles>
  );
}

export default GraduateStudents;
