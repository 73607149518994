import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import PeopleSectionHeader from '../../components/people/PeopleSectionHeader';
import { PeopleSectionStyles, PersonGrid, PersonContainer, PlacardStyle } from '../../styles/components/People';
import { GatsbyImage } from 'gatsby-plugin-image';

const PersonImageContainer = styled.div`
  width: 220px;
`;

const Faculty = () => {
  const facultyData = useStaticQuery(graphql`
    query FacultyImages {
      ashokImage: file(relativePath: { eq: "ashok-agrawala.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      royImage: file(relativePath: { eq: "nirupam-roy.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <PeopleSectionStyles className="people-section">
      <PeopleSectionHeader title="Faculty" />

      <PersonGrid>

        <PersonContainer className="person">
          <PersonImageContainer className="person-image-container">
            <GatsbyImage
              image={facultyData.ashokImage.childImageSharp.gatsbyImageData}
              alt="Professor Ashok Agrawala"
            />
          </PersonImageContainer>

          <PlacardStyle className="placard">
            <a target="_blank" rel="noopener noreferrer" href="https://www.cs.umd.edu/~agrawala/">
              Professor Ashok Agrawala
            </a>
          </PlacardStyle>
        </PersonContainer>

        <PersonContainer className="person">
          <PersonImageContainer className="person-image-container">
            <GatsbyImage
              image={facultyData.royImage.childImageSharp.gatsbyImageData}
              alt="Professor Nirupam Roy"
            />
          </PersonImageContainer>

          <PlacardStyle className="placard">
            <a target="_blank" rel="noopener noreferrer" href="https://www.cs.umd.edu/~nirupam/">
              Professor Nirupam Roy
            </a>
          </PlacardStyle>
        </PersonContainer>

      </PersonGrid>
    </PeopleSectionStyles>
  );
}

export default Faculty;
