import React from 'react';
import styled from 'styled-components';

export const PeopleTitle = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  height: auto;
`;

export const PeopleTitleHeaderContainer = styled.div`
  display: flex;
  margin: 4rem;
`;

export const PeopleTitleHeaderLine = styled.div`
  display: flex;
  flex-grow: 1;
  border-style: solid;
  align-self: center;
  margin-right: 1rem;
  margin-left: 1rem;
  border-color: rgba(156, 163, 175, 1);
`;

const PeopleSectionHeader = ({ title }) => (
  <PeopleTitleHeaderContainer>
    <PeopleTitleHeaderLine></PeopleTitleHeaderLine>
    <div style={{ marginBottom: 0 }}>
      <h5>
        <PeopleTitle>{title}</PeopleTitle>
      </h5>
    </div>
    <PeopleTitleHeaderLine></PeopleTitleHeaderLine>
  </PeopleTitleHeaderContainer>
);

export default PeopleSectionHeader;
