import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import { GatsbyImage } from 'gatsby-plugin-image';
import PeopleSectionHeader from './PeopleSectionHeader';
import { PeopleSectionStyles, PersonGrid, PersonContainer, PlacardStyle } from '../../styles/components/People';

const PersonImageContainer = styled.div`
  width: 220px;
`;

const Associates = function () {
  const associateData = useStaticQuery(graphql`
    query AssociateImages {
      noImage: file(relativePath: { eq: "no-profile-photo.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      daleImage: file(relativePath: { eq: "dale-chang.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      samImage: file(relativePath: { eq: "sam-attwood.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <PeopleSectionStyles className="people-section">
      <PeopleSectionHeader title="Associates" />

      <PersonGrid>
        <PersonContainer className="person">
          <PersonImageContainer className="person-image-container">
            <GatsbyImage image={associateData.samImage.childImageSharp.gatsbyImageData} alt="Sam Attwood" />
          </PersonImageContainer>

          <PlacardStyle className="placard">
            <p>Sam Attwood</p>
          </PlacardStyle>
        </PersonContainer>

        <PersonContainer className="person">
          <PersonImageContainer className="person-image-container">
            <GatsbyImage image={associateData.daleImage.childImageSharp.gatsbyImageData} alt="Dale Chang" />
          </PersonImageContainer>

          <PlacardStyle className="placard">
            <p>Dale Chang</p>
          </PlacardStyle>
        </PersonContainer>

        <PersonContainer className="person">
          <PersonImageContainer className="person-image-container">
            <GatsbyImage image={associateData.noImage.childImageSharp.gatsbyImageData} alt="Andrew Pachulski" />
          </PersonImageContainer>

          <PlacardStyle className="placard">
            <p>Andrew Pachulski</p>
          </PlacardStyle>
        </PersonContainer>
      </PersonGrid>
    </PeopleSectionStyles>
  );
};

export default Associates;
