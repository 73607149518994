import React, { useState, useEffect } from 'react'
import Collapsible from 'react-collapsible';
import personnelData from '../../../static/data/personnel.json';

import './CollapsibleStyle.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'


const CollapsibleStudents = ({componentSeason, componentYear}) => {
    const [year, setYear] = useState(0);
    const [season, setSeason] = useState("0");

    function setSeasonAndYear(clickSeason, clickYear) {
      useEffect(() => {
          setYear(clickYear)
        setSeason(clickSeason)
      }, [year, season]);
    }

    const semesterFilter = personnelData.filter(student => student.semesters.some(semesterList=> semesterList.season === season && semesterList.year == year));

    const highSchoolSemesterFilter = semesterFilter.filter((student) => student.position === 'High School Student');

    const undergraduateSemesterFilter = semesterFilter.filter((student) => student.position === 'Undergraduate Student');

    const graduateSemesterFilter = semesterFilter.filter((student) => student.position === 'Graduate Student' || student.position === 'Doctorate Candidate');

    const text= (componentSeason + " " + componentYear);

    return(
        <Collapsible trigger={[text, <FontAwesomeIcon icon={faChevronDown}/>]} onTriggerOpen={setSeasonAndYear(componentSeason, componentYear)}>
            {graduateSemesterFilter.length > 0?
                <>
                <p className={"position-header"}>Graduate Students</p>
                    {graduateSemesterFilter.map((student) => (
                    <p key={student.firstName + student.lastName}>
                        {student.firstName} {student.lastName}
                    </p>
                    ))}
                </>
                :<></>}

            {undergraduateSemesterFilter.length > 0?
                <>
                    <p className={"position-header"}>Undergraduate Students</p>
                    {undergraduateSemesterFilter.map((student) => (
                        <p key={student.firstName + student.lastName}>
                            {student.firstName} {student.lastName}
                        </p>
                    ))}
                </>
                :<></>}

            {highSchoolSemesterFilter.length > 0?
                <>
                    <p className={"position-header"}>High School Students</p>
                    {highSchoolSemesterFilter.map((student) => (
                        <p key={student.firstName + student.lastName}>
                            {student.firstName} {student.lastName}
                        </p>
                    ))}
                </>
                :<></>}
        </Collapsible>
    );
};

export default CollapsibleStudents;

