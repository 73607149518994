import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import PeopleSectionHeader from '../../components/people/PeopleSectionHeader';
import { PeopleSectionStyles, PersonGrid, PersonContainer, PlacardStyle } from '../../styles/components/People';
import { GatsbyImage } from 'gatsby-plugin-image';

const PersonImageContainer = styled.div`
  width: 220px;
`;

/**
 * List of Spring 2022 PhD Candidates
 * * Nirat Saini
 * @returns
 */
const PhdCandidates = () => {
  const phdCandidateData = useStaticQuery(graphql`
    query PhdCandidateImages {
      niratImage: file(relativePath: { eq: "no-profile-photo.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <PeopleSectionStyles className="people-section">
      <PeopleSectionHeader title="PhD Candidates" />

      <PersonGrid>

        <PersonContainer className="person">
          <PersonImageContainer className="person-image-container">
            <GatsbyImage
              image={phdCandidateData.niratImage.childImageSharp.gatsbyImageData}
              alt="Nirat Saini"
            />
          </PersonImageContainer>

          <PlacardStyle className="placard">
            <p>Nirat Saini</p>
          </PlacardStyle>
        </PersonContainer>

      </PersonGrid>
    </PeopleSectionStyles>
  );
}

export default PhdCandidates;
