import React from 'react';
import styled from 'styled-components';
import '../components/people/CollapsibleStyle.css';

import { Layout, SEO } from '../components/common';
import { Container, PageWrapper, Details } from '../styles';
import { Header } from '../components/theme';

import PeopleSectionHeader from '../components/people/PeopleSectionHeader';

import Faculty from '../components/people/Faculty';
import LeadScientists from '../components/people/LeadScientists';
import GraduateStudents from '../components/people/GraduateStudents';
import PhdCandidates from '../components/people/PhdCandidates';
import Associates from '../components/people/Associates';
import Contributors from '../components/people/UndergraduateHighSchoolStudents';
import CollapsibleStudents from '../components/people/CollapsibleStudents';

const PeopleSectionStyle = styled.section`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const GroupPhotoCaptionStyles = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  //font-style: italic;
  align-self: center;
`;

const People = function () {
  const seasonYears = [
    { year: 2021, season: 'Fall' },
    { year: 2021, season: 'Summer' },
    { year: 2021, season: 'Spring' },
    { year: 2020, season: 'Fall' },
    { year: 2020, season: 'Summer' },
    { year: 2020, season: 'Spring' },
    { year: 2019, season: 'Fall' },
    { year: 2019, season: 'Summer' },
    { year: 2019, season: 'Spring' },
    { year: 2018, season: 'Fall' },
    { year: 2018, season: 'Summer' },
    { year: 2018, season: 'Spring' },
    { year: 2017, season: 'Fall' },
    { year: 2017, season: 'Summer' },
    { year: 2017, season: 'Spring' },
    { year: 2016, season: 'Fall' },
  ];

  const collapsibleStudents = seasonYears.map((sy, index) => (
    <CollapsibleStudents key={index} componentSeason={sy.season} componentYear={sy.year} />
  ));

  return (
    <Layout>
      <SEO />
      <Header />
      <PageWrapper as={Container}>
        <Details>
          <h1>People</h1>
          <article>
            <Faculty />

            <LeadScientists />

            <PhdCandidates />

            <GraduateStudents />

            <Contributors />

            <Associates />

            <PeopleSectionStyle className="people-section">
              <PeopleSectionHeader title="Previous Students" />
              <GroupPhotoCaptionStyles className="current-students">
                <div className="collapsible-container">{collapsibleStudents}</div>
              </GroupPhotoCaptionStyles>
            </PeopleSectionStyle>
          </article>
        </Details>
      </PageWrapper>
    </Layout>
  );
};

export default People;
