import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import PeopleSectionHeader from '../../components/people/PeopleSectionHeader';
import { PeopleSectionStyles, PersonGrid, PersonContainer, PlacardStyle } from '../../styles/components/People';
import { GatsbyImage } from 'gatsby-plugin-image';

import personnelData from '../../../static/data/personnel.json';

const PersonImageContainer = styled.div`
  width: 220px;
`;

const PeopleSectionStyle = styled.section`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const GridStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  @media (min-width: 640px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const ShortListStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LongListStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row: span 2 / span 2;
`;

const ListStyles = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
`;

const Contributors = () => {
  const activeStudents = personnelData
    .filter((person) => person.isActive && !person.isEmployed)
    .sort((a, b) => {
      if (a.lastName < b.lastName) {
        return -1;
      }
      if (a.lastName > b.lastName) {
        return 1;
      }
      if (a.firstName < b.firstName) {
        return -1;
      }
      if (a.firstName > b.firstName) {
        return 1;
      }
      return 0;
    });

  const activeHighSchoolStudents = activeStudents.filter((student) => student.position === 'High School Student');
  const activeUndergraduateStudents = activeStudents.filter((student) => student.position === 'Undergraduate Student');

  return (
    <PeopleSectionStyle className="people-section">
      <PeopleSectionHeader title="Current Students" />

      <GridStyles className="current-students">
        <LongListStyles>
          <h5>Undergraduate Students</h5>
          <ListStyles>
            {activeUndergraduateStudents.map((student) => (
              <li key={student.firstName}>
                {student.link ? (
                  <a target="_blank" rel="noopener noreferrer" href={student.link}>
                    {student.name}
                  </a>
                ) : (
                  <>
                    {student.firstName} {student.lastName}
                  </>
                )}
              </li>
            ))}
          </ListStyles>
        </LongListStyles>

        <ShortListStyles>
          <h5>High School Students</h5>
          <ListStyles>
            {activeHighSchoolStudents.map((student) => (
              <li key={student.firstName}>
                {student.link ? (
                  <a target="_blank" rel="noopener noreferrer" href={student.link}>
                    {student.name}
                  </a>
                ) : (
                  <>
                    {student.firstName} {student.lastName}
                  </>
                )}
              </li>
            ))}
          </ListStyles>
        </ShortListStyles>
      </GridStyles>
    </PeopleSectionStyle>
  );
}

export default Contributors;
