import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import PeopleSectionHeader from '../../components/people/PeopleSectionHeader';
import { PeopleSectionStyles, PersonGrid, PersonContainer, PlacardStyle } from '../../styles/components/People';
import { GatsbyImage } from 'gatsby-plugin-image';

const PersonImageContainer = styled.div`
  width: 220px;
`;

const LeadScientists = () => {
  const leadScientistsData = useStaticQuery(graphql`
    query LeadScientistsImages {
      faizanImage: file(relativePath: { eq: "faizan-wajid.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      maraImage: file(relativePath: { eq: "mara-cai.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <PeopleSectionStyles className="people-section">
      <PeopleSectionHeader title="Lead Scientists" />

      <PersonGrid>

        <PersonContainer className="person">
          <PersonImageContainer className="person-image-container">
            <GatsbyImage
              image={leadScientistsData.faizanImage.childImageSharp.gatsbyImageData}
              alt="Faizan Wajid"
            />
          </PersonImageContainer>

          <PlacardStyle className="placard">
            <p>Faizan Wajid</p>
          </PlacardStyle>
        </PersonContainer>

        <PersonContainer className="person">
          <PersonImageContainer className="person-image-container">
            <GatsbyImage
              image={leadScientistsData.maraImage.childImageSharp.gatsbyImageData}
              alt="Mara Cai"
            />
          </PersonImageContainer>

          <PlacardStyle className="placard">
            <p>Mara Cai</p>
          </PlacardStyle>
        </PersonContainer>

      </PersonGrid>
    </PeopleSectionStyles>
  );
}

export default LeadScientists;
